import styled from "@emotion/styled";
import React, { useContext, useRef } from "react";
import { ConfigContext } from "../util/state";
import { Box } from "@chakra-ui/react";

const Scene = styled.div((props) => {
  const {
    yRotation,
    vRotation,
    height,
    width,
    thickness,
    sceneHeight,
    sceneWidth,
    perspectiveX,
    perspectiveY,
    bgColor,
    flipX,
    flipY,
    transparent,
  } = props;

  return {
    height: `${sceneHeight}px`,
    width: `${sceneWidth}px`,
    perspective: `${1.5 * height}px`,
    perspectiveOrigin: `${perspectiveX}% -${perspectiveY}%`,
    outline: "1px solid #aaa",
    background: transparent ? "transparent" : bgColor,
    boxShadow: transparent ? "" : "0px 0px 200px -45px rgb(0 0 0 / 75%) inset",
    transform: `scaleX(${flipX})`,
  };
});
const Rotator = styled.div((props) => {
  const { yRotation, vRotation, height, width, thickness, perspectiveX } =
    props;

  return {
    width: "100%",
    height: "100%",
    outline: "1px solid #aaa",
  };
});

const Cube = styled.div((props) => {
  const {
    yRotation,
    vRotation,
    height,
    width,
    thickness,
    sceneHeight,
    sceneWidth,
    flipX,
    flipY,
  } = props;
  return {
    width: `${width}px`,
    height: `${height}px`,
    position: `relative`,
    transformStyle: `preserve-3d`,
    transition: `transform 1s`,
    transform: `rotateY(${yRotation}deg) translateX(${
      sceneWidth / 2
    }px) translateY(${
      (sceneHeight - height / 2) / 2
    }px) rotateZ(${vRotation}deg)`,
  };
});

const CubeFace = styled.div((props) => {
  const { rotation, vRotation, height, width, thickness, fontSize } = props;
  return {
    position: `absolute`,
    width: `${width}px`,
    height: `${height}px`,
    fontSize: `40px`,
    fontWeight: `bold`,
    color: `white`,
    textAlign: `center`,
  };
});
const FrontFace = styled(CubeFace)((props) => {
  const {
    rotation,
    vRotation,
    fontSize,
    height,
    width,
    thickness,
    hue,
    color1,
    textSize1,
    textSize2,
  } = props;

  return {
    transform: ``,
    overflow: "hidden",
    borderTopWidth: "2px",
    borderColor: color1,
    bordeStyle: "outset",
    "&:before": {
      position: "absolute",
      content: `" "`,
      height: "100%",
      width: "100%",
      left: 0,
      top: "-2px",
      filter: "opacity(0.5)",
      background: `linear-gradient(180deg, #fffb, transparent)`,
    },
  };
});
const Section1 = styled("div")((props) => {
  const { color1, sections, textColor1, textSize1 } = props;

  return {
    background: color1,
    height: sections === 1 ? "100%" : "70%",
    color: textColor1,
    padding: "8%",
    textAlign: "left",
    fontSize: `${textSize1 / 50}rem`,
  };
});
const Section2 = styled("div")((props) => {
  const {
    rotation,
    vRotation,
    fontSize,
    height,
    width,
    thickness,
    hue,
    color2,
    sections,
    textColor2,
    textSize2,
  } = props;

  return {
    background: color2,
    height: sections === 1 ? "0" : "30%",
    color: textColor2,
    padding: "8%",
    textAlign: "left",
    fontSize: `${textSize2 / 50}rem`,
  };
});
const LeftSection1 = styled("div")((props) => {
  const { color1, sections, flipX, flipY } = props;
  return {
    background: color1,
    height: sections === 1 ? "100%" : "70%",
    width: "100%",
  };
});
const LeftSection2 = styled("div")((props) => {
  const {
    rotation,
    vRotation,
    fontSize,
    height,
    width,
    thickness,
    hue,
    flipX,
    flipY,
    color2,
    sections,
  } = props;

  return {
    background: color2,
    height: sections === 1 ? "0" : "30%",
    width: "100%",
  };
});
const BackFace = styled(CubeFace)((props) => {
  const { rotation, vRotation, height, width, thickness, hue, color1 } = props;

  return {
    background: color1,
    overflow: "hidden",
    transform: `translateZ(-${thickness}px)`,
    filter: "drop-shadow(-80px 40px 60px #000a)",
  };
});
const LeftFace = styled(CubeFace)((props) => {
  const {
    rotation,
    vRotation,
    sideTextFontSize,
    height,
    width,
    thickness,
    hue,
    color1,
  } = props;
  return {
    width: `${thickness}px`,
    background: color1,
    fontSize: `${sideTextFontSize * 0.5}px`,
    transformOrigin: `100% 100% 0`,
    transform: `rotateY(-90deg) translateZ(${thickness}px)`,
    writingMode: "vertical-rl",
    textOrientation: "sideways-right",
    display: "flex",
  };
});
const TopFace = styled(CubeFace)((props) => {
  const { color1, rotation, vRotation, height, width, thickness, hue } = props;
  return {
    background: color1,

    height: `${thickness}px`,
    transformOrigin: `0 0 0`,
    transform: `rotateX(90deg) translateY(-${thickness}px)`,
    // borderBottomRightRadius: "4px",
    // borderBottom: "4px solid black",
    overflow: "hidden",
    "&:before": {
      position: "absolute",
      content: `" "`,
      height: "100%",
      width: "100%",
      left: 0,
      top: 0,
      background: "#fffb",
      outline: "1px solid black",

      filter: "opacity(0.5)",
    },
  };
});
const Shadow = styled(CubeFace)((props) => {
  const { rotation, vRotation, height, width, thickness, hue } = props;
  return {
    background: `#000`,

    height: `${height}px`,
    transformOrigin: `0 0 0`,
    transform: `rotateX(90deg) translateY(-${height}px) translateZ(-${height}px) `,
    // borderBottomRightRadius: "4px",
    // borderBottom: "4px solid black",
    overflow: "hidden",
    "&:before": {
      position: "absolute",
      content: `" "`,
      height: "100%",
      width: "100%",
      left: 0,
      top: 0,
      background: "#fffb",
      outline: "1px solid black",

      filter: "opacity(0.5)",
    },
  };
});
// const BackFace = styled(CubeFace)((props) => ({
//   transform: `rotateY(180deg) translateZ(100px)`,
//   background: `hsla(120, 100%, 50%, 0.7)`,
// }));
// const RightFace = styled(CubeFace)((props) => ({
//   transform: `rotateY(90deg) translateZ(100px)`,
//   background: `hsla(60, 100%, 50%, 0.7)`,
// }));

// const BottomFace = styled(CubeFace)((props) => ({}));

const PreText = styled("pre")((props) => {
  const { flipX, flipY } = props;
  return {
    transform: `scaleX(${flipX}) `,
  };
  // return (<pre>{props.children}</pre>)
});

const Main = ({ sceneRef }) => {
  const [state, dispatch] = useContext(ConfigContext);
  const { sections } = state;

  return (
    <Scene className="scene" {...state} ref={sceneRef}>
      <Cube {...state}>
        <BackFace {...state}></BackFace>
        <FrontFace {...state}>
          <Section1 {...state}>
            <PreText {...state}>{state.text1}</PreText>
          </Section1>
          {sections === 2 && (
            <Section2 {...state}>
              <PreText {...state}>{state.text2}</PreText>
            </Section2>
          )}
        </FrontFace>
        <LeftFace {...state}>
          {/* {state.sideText} */}
          <LeftSection1 {...state}></LeftSection1>
          {sections === 2 && <LeftSection2 {...state}></LeftSection2>}
        </LeftFace>
        <TopFace {...state}></TopFace>
      </Cube>
    </Scene>
  );
};

export default Main;
