import React, { useState, useRef, useEffect } from "react";
import { requireAuth, useAuth } from "../util/auth";
import {
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Alert,
  useToast,
} from "@chakra-ui/react";
import { useRouter } from "../util/router.js";
import { useForm } from "react-hook-form";

import { FcGoogle } from "react-icons/fc";
import { FaTwitter } from "react-icons/fa";

function Login(props) {
  const toast = useToast();
  const router = useRouter();
  const type = router.query.type;
  const allTypeValues = {
    signin: {
      // Top title
      title: "Welcome back",
      // Submit button text
      buttonText: "Sign in",
      // Link text to other auth types
      linkText: "Create an account",
      linkTextForgotpass: "Forgot Password?",
    },
    signup: {
      title: "Create an account",
      buttonText: "Sign up",
      linkText: "Sign in if you have an account",
    },
    forgotpass: {
      title: "Get a new password",
      buttonText: "Reset password",
    },
    changepass: {
      title: "Choose a new password",
      buttonText: "Change password",
    },
  };
  const currentType = allTypeValues[type] ? type : "signup";
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const typeValues = allTypeValues[currentType];

  // Ensure we have a valid auth type

  const afterAuthPath = router.query.next || "/editor";
  const isSignup = type === "signup";

  const auth = useAuth();
  const [pending, setPending] = useState(null);
  const [lastUsed, setLastUsed] = useState(null);

  const handleAuth = (user) => {
    // TODO: show logged in alert
    router.push(afterAuthPath);
  };
  const onSigninWithProvider = (provider) => {
    setPending(provider);
    auth
      .signinWithProvider(provider)
      .then((user) => {
        localStorage.setItem("lastUsedAuthProvider", provider);
        handleAuth(user);
      })
      .catch((error) => {
        setPending(null);
        toast({
          title: error.message,
          status: "error",
          isClosable: true,
        });
        console.log(error.message);
      });
  };

  // Get value of last used auth provider
  // useEffect(() => {
  //   if (props.showLastUsed) {
  //     const lastUsed = window.localStorage.getItem("lastUsedAuthProvider");
  //     if (lastUsed) {
  //       setLastUsed(lastUsed);
  //     }
  //   }
  // }, [props.showLastUsed]);

  const submitHandlersByType = {
    signin: ({ email, password }) => {
      return auth.signin(email, password).then((user) => {
        // Call auth complete handler
        handleAuth("");
      });
    },
    signup: ({ email, password }) => {
      return auth.signup(email, password).then((user) => {
        // Call auth complete handler
        console.log(user);
      });
    },
    forgotpass: ({ email }) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        // Show success alert message
        console.log({
          type: "success",
          message: "Password reset email sent",
        });
      });
    },
    changepass: ({ password }) => {
      return auth.confirmPasswordReset(password).then(() => {
        setPending(false);
        // Show success alert message
        console.log({
          type: "success",
          message: "Your password has been changed",
        });
      });
    },
  };
  const onSubmit = (values) => {
    const { email, password } = values;

    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[type]({
      email,
      password,
    }).catch((error) => {
      setPending(false);
      // Show error alert message
      console.log({
        type: "error",
        message: error.message,
      });
    });
  };

  return (
    <Flex
      minH={"100vh"}
      minW={"100%"}
      align={"center"}
      justify={"center"}
      bg={"gray.50"}
    >
      <Stack spacing={8} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>{typeValues["title"]}</Heading>

          <Button
            onClick={() => {
              if (isSignup) {
                router.push("/auth/signin");
              } else {
                router.push("/auth/signup");
              }
            }}
            variant="link"
            colorScheme={"red"}
          >
            or {typeValues["linkText"].toLowerCase()}
          </Button>
          {/* <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link color={"blue.400"}>features</Link> ✌️
          </Text> */}
        </Stack>
        <Box
          rounded={"lg"}
          bg={"white"}
          boxShadow={"lg"}
          p={8}
          minW={{ base: "400px" }}
          maxW={"420px"}
        >
          <Stack spacing={4}>
            {/* <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel htmlFor="email">Email address</FormLabel>
                <Input
                  // name="email"
                  id="email"
                  type="email"
                  {...register("email", { required: true })}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  // name="password"
                  type="password"
                  id="password"
                  {...register("password", { required: true })}
                />
              </FormControl>
              {/* <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
                >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.400"}>Forgot password?</Link>
              </Stack> 
              <Button colorScheme="red" w="full" my={8} type="submit">
                {typeValues["buttonText"]}
              </Button>
            </form> */}
            <Stack spacing={10}>
              <Text fontSize="lg" w="full" textAlign="center">
                {typeValues["buttonText"]} using
              </Text>
              <Stack align="center" justify="center">
                <Button
                  colorScheme="red"
                  variant="outline"
                  w="full"
                  onClick={() => onSigninWithProvider("google")}
                  leftIcon={<FcGoogle />}
                >
                  Google
                </Button>
                <Button
                  colorScheme="twitter"
                  variant="outline"
                  w="full"
                  onClick={() => onSigninWithProvider("twitter")}
                  leftIcon={<FaTwitter />}
                >
                  Twitter
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Login;
