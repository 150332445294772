import React, { useReducer, createContext } from "react";
import { useAuth } from "./auth";

export const initialState = (isPro = false) => {
  return {
    sections: isPro ? 2 : 1,
    sceneHeight: 400,
    sceneWidth: 650,
    perspectiveY: 20,
    perspectiveX: 0,

    height: 250,
    width: 200,
    vRotation: 0,
    yRotation: 10,
    thickness: 40,
    color1: "black",
    color2: "#A62626",
    bgColor: "white",

    textColor1: "white",
    textColor2: "#2222222",
    textSize1: 90,
    textSize2: 50,
    textColorSide: "white",

    text1: `INDIE
HACKING
101`,
    text2: `RAJAT KAPOOR`,
    sideText: " ",

    fontSize: 24,
    sideTextFontSize: 24,
    version: 0,

    flipX: 1,
    flipY: 1,
    transparent: false,
    isPro,
  };
};

// this function will determine how the state is updated
export const reducer = (state, action) => {
  if (
    ["BGCOLOR", "PERSPECTIVE", "TRANSPARENT", "SECTIONS", "ROTATION"].includes(
      action.type
    ) &&
    !state.isPro
  ) {
    return state;
  }
  switch (action.type) {
    case "SECTIONS":
      return { ...state, sections: action.sections };
    case "TEXT":
    case "ROTATION":
    case "COLOR":
    case "BGCOLOR":
    case "PERSPECTIVE":
    case "DIMENSION":
      const { type, ...others } = action;
      return { ...state, ...others };
    case "TRANSPARENT":
      return { ...state, transparent: action.transparent };
    case "FLIP":
      const { direction } = action;
      if (direction === "x") {
        const newFlipX = state.flipX * -1;
        return {
          ...state,
          flipX: newFlipX,
        };
      } else if (direction === "y") {
        const newFlipY = state.flipY * -1;
        return {
          ...state,
          flipY: newFlipY,
        };
      }

    //     case 'DECREMENT':
    //       return { count: state.count - 1 }
    //     case 'REPLACE':
    //       return { count: action.newCount }
    //     case 'RESET':
    //       return { count: 0 }
    default:
      return state;
  }
};

export const ConfigContext = createContext();

export const ConfigContextProvider = (props) => {
  const { user } = useAuth();
  const proId = "4Y4QmWlFSeqm7S1QkIlqrw==";
  const isPro = user.productId === proId;
  let init;
  try {
    init = JSON.parse(window.localStorage.getItem("__ss"));
    if (!!init.sections) {
      init = initialState(isPro);
    }
  } catch (e) {
    init = initialState(isPro);
  }
  const [state, dispatch] = useReducer(reducer, { ...init, isPro });

  return (
    <ConfigContext.Provider value={[state, dispatch, isPro]}>
      {props.children}
    </ConfigContext.Provider>
  );
};
