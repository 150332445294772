import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { useAuth } from "../util/auth";
import AuthButton from "./AuthButton";
import { useRouter } from "../util/router.js";

import { MdSettings, MdExitToApp, MdVerifiedUser } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";

export default function Navbar() {
  const auth = useAuth();
  const { user } = auth;
  const router = useRouter();
  const inApp = !(
    router.location.pathname === "" || router.location.pathname === "/"
  );

  return (
    <Box bg="white" w="full">
      <Flex minH={"60px"} paddingY={4} align={"center"}>
        <Flex
          cursor={"pointer"}
          flex={{ base: 1, grow: 1 }}
          justify={"start"}
          alignItems={"center"}
        >
          <Heading
            fontSize={["2xl", "4xl"]}
            color={"red.500"}
            fontFamily={"cursive"}
            cursor={"pointer"}
            onClick={() => {
              if (inApp) {
                router.push("/editor");
              } else {
                router.push("/");
              }
            }}
          >
            Mockly
          </Heading>
        </Flex>
        {!inApp && (
          <Stack
            flex={{ base: 1 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <AuthButton
              onClick={() => {
                if (!user) {
                  router.push("/auth/signup");
                } else {
                  router.push("/editor");
                }
              }}
            />
          </Stack>
        )}
        {inApp && user && (
          <Stack
            flex={{ base: 1 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <Popover
              placement="bottom-end"
              closeOnEsc
              closeOnBlur
              matchWidth
              returnFocusOnClose={false}
            >
              <PopoverTrigger>
                <Button variant={"ghost"} leftIcon={<FaUserCircle />}>
                  {user.name || "Manage"}
                </Button>
              </PopoverTrigger>
              <PopoverContent w="auto">
                <PopoverBody>
                  <Button
                    w="full"
                    size="lg"
                    variant="ghost"
                    onClick={() => {
                      router.push("/settings");
                    }}
                    my={2}
                    leftIcon={<MdSettings />}
                  >
                    Settings
                  </Button>
                  <Button
                    my={2}
                    w="full"
                    size="lg"
                    colorScheme={"red"}
                    onClick={auth.signout}
                    leftIcon={<MdExitToApp />}
                  >
                    Logout
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Stack>
        )}
      </Flex>
    </Box>
  );
}
