import React, { useState, useContext } from "react";
import { ConfigContext } from "../util/state";
import {
  Stack,
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Input,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  Button,
  Icon,
  TabPanels,
  Flex,
  Text,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useOutsideClick,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { VscSplitHorizontal, VscSplitVertical } from "react-icons/vsc";

import TextControl from "./TextControl";
import ColorPicker from "./ColorPicker";
import SliderControl from "./SliderControl";
import ProOnlyBadge from "./ProOnlyBadge";

const ProductPanel = () => {
  const [state, dispatch, isPro] = useContext(ConfigContext);
  const {
    sections,
    text1,
    text2,
    color1,
    color2,
    textColor1,
    textColor2,
    textSize1,
    textSize2,
    width,
    thickness,
    height,
    vRotation,
  } = state;
  return (
    <Stack spacing={4}>
      <RadioGroup
        onChange={(val) =>
          dispatch({ type: "SECTIONS", sections: parseInt(val) })
        }
        value={state.sections}
      >
        <Stack direction="row">
          <Radio value={1}>One Section</Radio>

          <Radio disabled={!isPro} value={2}>
            Two Section {!isPro && <ProOnlyBadge />}
          </Radio>
        </Stack>
      </RadioGroup>
      <TextControl
        name={sections === 2 ? "Section 1 text" : "Text"}
        onChange={(val) => dispatch({ type: "TEXT", text1: val })}
        value={text1}
      />

      <ColorPicker
        name={sections === 2 ? "Section 1 color" : "color"}
        color={color1}
        setColor={(color) => {
          dispatch({ type: "COLOR", color1: color.hex });
        }}
      />
      <ColorPicker
        name={sections === 2 ? "Section 1 text color" : "text color"}
        color={textColor1}
        setColor={(color) => {
          dispatch({ type: "COLOR", textColor1: color.hex });
        }}
      />
      <SliderControl
        name={sections === 2 ? "Section 1 Text Size" : "text size"}
        min={50}
        max={500}
        onChange={(val) => {
          dispatch({ type: "DIMENSION", textSize1: val });
        }}
        value={textSize1}
      />

      {sections === 2 && (
        <TextControl
          name={"Section 2 text"}
          onChange={(val) => dispatch({ type: "TEXT", text2: val })}
          value={text2}
        />
      )}
      {sections === 2 && (
        <ColorPicker
          name={sections === 2 ? "Section 2  color" : "color"}
          color={color2}
          setColor={(color) => {
            dispatch({ type: "COLOR", color2: color.hex });
          }}
        />
      )}
      {sections === 2 && (
        <ColorPicker
          name={sections === 2 ? "Section 2 text color" : "text color"}
          color={textColor2}
          setColor={(color) => {
            dispatch({ type: "COLOR", textColor2: color.hex });
          }}
        />
      )}
      {sections === 2 && (
        <SliderControl
          name={sections === 2 ? "Section 2 Text size" : "text size"}
          min={25}
          max={400}
          onChange={(val) => {
            dispatch({ type: "DIMENSION", textSize2: val });
          }}
          value={textSize2}
        />
      )}
      <SliderControl
        name="width"
        min={100}
        max={400}
        onChange={(val) => {
          dispatch({ type: "DIMENSION", width: val });
        }}
        value={width}
      />
      <SliderControl
        name="thickness"
        min={10}
        max={100}
        onChange={(val) => {
          dispatch({ type: "DIMENSION", thickness: val });
        }}
        value={thickness}
      />
      <SliderControl
        name="Height"
        min={100}
        max={350}
        onChange={(val) => {
          dispatch({ type: "DIMENSION", height: val });
        }}
        value={height}
      />
      <SliderControl
        name="Rotation"
        min={-15}
        max={15}
        onChange={(val) => {
          dispatch({ type: "ROTATION", vRotation: val });
        }}
        value={vRotation}
        disabled={!isPro}
      />
      {/* <TextControl
        name={"Side text"}
        onChange={(val) => dispatch({ type: "TEXT", sideText: val })}
        value={sideText}
      /> */}
      {/* <Button
        onClick={() => dispatch({ type: "FLIP", direction: "x" })}
        aria-label="Flip horizontal"
        leftIcon={<VscSplitHorizontal />}
      >
        Flip horizontal
      </Button>
      <Button
        onClick={() => dispatch({ type: "FLIP", direction: "y" })}
        aria-label="Flip vertical"
        leftIcon={<VscSplitVertical />}
      >
        Flip vertical
      </Button> */}
    </Stack>
  );
};

export default ProductPanel;
