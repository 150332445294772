import React from "react";
import SettingsSection from "./../components/SettingsSection";
import { useRouter } from "./../util/router.js";
import { requireAuth, useAuth } from "./../util/auth.js";
import {
  Box,
  Flex,
  Stack,
  Text,
  Heading,
  Button,
  Alert,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  AlertIcon,
  Link,
  AlertTitle,
  AlertDescription,
  Badge,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import ProOnlyBadge from "../components/ProOnlyBadge";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

const Setting = ({ name, value }) => (
  <Box>
    <Text fontSize="lg" fontWeight="bold">
      {name}
    </Text>
    <Text fontSize="lg">{value}</Text>
  </Box>
);

function SettingsPage(props) {
  const ref = React.createRef(null);
  const router = useRouter();
  const buyIntent = router.query.tab === "buy";
  const tabIndex = router.query.tab === "plan" || buyIntent ? 1 : 0;

  const {
    user: { productName, price, licenseKey, email, name, productId },
  } = useAuth();

  const isPro = productId === "4Y4QmWlFSeqm7S1QkIlqrw==";
  // React.useEffect(() => {
  //   if (!isPro && buyIntent && ref && ref.current) {
  //     ref.current.click();
  //   }
  // }, [isPro, buyIntent]);

  return (
    <Stack bg="gray.50" height={"100vh"}>
      <Box
        border="2px"
        borderColor="gray.200"
        w={"full"}
        paddingX={16}
        bg="white"
      >
        <Navbar />
      </Box>
      <Flex py={8} w="full" align="center" justify="center">
        <Stack w="full" align="center" justify="center" px={[2, 0]}>
          {!isPro && (
            <Box
              w={["375px", "500px"]}
              align="center"
              justify="center"
              px={[2, 0]}
            >
              <Alert status="warning" rounded={4}>
                <AlertIcon />
                <AlertTitle mr={2}>
                  You do not have access to <ProOnlyBadge disableClick={true} />{" "}
                  features
                </AlertTitle>
              </Alert>
            </Box>
          )}
          <Tabs defaultIndex={tabIndex}>
            <TabList>
              <Tab>Account Settings</Tab>
              <Tab>Plans</Tab>
            </TabList>

            <TabPanels minW={["375px", "500px"]}>
              <TabPanel>
                <Stack
                  bg="gray.100"
                  py={8}
                  px={12}
                  rounded={16}
                  boxShadow={"base"}
                  spacing={8}
                  w="full"
                >
                  {name && <Setting name="Name" value={name} />}
                  <Setting name="Email" value={email} />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack
                  bg="gray.100"
                  py={8}
                  px={12}
                  rounded={16}
                  boxShadow={"base"}
                  spacing={8}
                  w="full"
                >
                  <Setting name="Current Plan" value={productName} />
                  {!isPro && (
                    <Stack spacing={2}>
                      <Text fontSize="lg" fontWeight="bold">
                        Upgrage to get access to{" "}
                        <ProOnlyBadge disableClick={true} /> features
                      </Text>

                      <Stack
                        bgColor="white"
                        rounded={8}
                        boxShadow="xl"
                        onClick={() => {
                          ref.current.click();
                        }}
                        overflow="hidden"
                        cursor="pointer"
                        transform="scale(1) translateY(0px)"
                        _hover={{
                          boxShadow: "2xl",
                        }}
                        transition="transform 0.1s ease-out"
                        paddingTop={2}
                      >
                        <Stack px={8}>
                          <Stat>
                            <StatLabel fontSize="lg">
                              Mockly <ProOnlyBadge disableClick={true} />
                            </StatLabel>
                            <StatNumber>
                              <Text
                                textDecoration="line-through"
                                color="gray.300"
                              >
                                $9.00
                              </Text>
                              <Text>$5.00 </Text>
                            </StatNumber>
                            <StatHelpText>
                              (use code "earlybird" while checkout)
                            </StatHelpText>
                            <StatHelpText>Lifetime</StatHelpText>
                            <StatHelpText>Unlimited mockups</StatHelpText>
                            <StatHelpText>Access to all feautre</StatHelpText>
                          </Stat>
                        </Stack>
                        <Button variant="ghost" colorScheme="teal" mx={8}>
                          Upgrade now
                        </Button>
                        <Button
                          variant="ghost"
                          as={"a"}
                          colorScheme="teal"
                          mx={8}
                          href={`https://gumroad.com/l/jpRWU?wanted=true&price=5&email=${email}`}
                          target="_blank"
                          data-gumroad-single-product="true"
                          rel="noreferrer"
                          ref={ref}
                          display="none"
                        >
                          Upgrade now
                        </Button>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Flex>
    </Stack>
  );
}

export default requireAuth(SettingsPage);
