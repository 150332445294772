import React, { useState, useContext } from "react";
import { ConfigContext } from "../util/state";
import { ChromePicker, SketchPicker } from "react-color";
import ProOnlyBadge from "./ProOnlyBadge";

import {
  Stack,
  Input,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  StatLabel,
} from "@chakra-ui/react";

const SliderControl = ({ onChange, value, name, min, max, step, disabled }) => {
  step = step || 1;
  return (
    <Stack w={"full"} justify="start" align="start">
      <Text fontWeight="semibold" size="lg" textTransform="uppercase">
        {name} {disabled && <ProOnlyBadge />}
      </Text>
      <Stack direction="row" w="full">
        <Slider
          step={step}
          aria-label={`slider-${name.toLowerCase()}`}
          onChange={onChange}
          value={value}
          min={min}
          max={max}
          marginRight={8}
          disabled={disabled}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        {/* <Input
          type={"number"}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          width={"90px"}
        /> */}
        <StatLabel>{value}</StatLabel>
      </Stack>
    </Stack>
  );
};
export default SliderControl;
