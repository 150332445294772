import React, { useState, useContext } from "react";
import { ConfigContext } from "../util/state";
import { ChromePicker, SketchPicker } from "react-color";
import { Textarea } from "@chakra-ui/react";

import {
  Stack,
  Input,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
} from "@chakra-ui/react";

import { FaEdit } from "react-icons/fa";

const TextControl = ({ onChange, value, name }) => {
  return (
    <Stack w={"full"} justify="start" align="start">
      <Text fontWeight="semibold" size="lg" textTransform="uppercase">
        {name}
      </Text>
      <Textarea value={value} onChange={(e) => onChange(e.target.value)} />
    </Stack>
  );
};
export default TextControl;
