import React from "react";

import { Button } from "@chakra-ui/react";

const AuthButton = ({ name, onClick, size }) => {
  size = size || "lg";
  return (
    <Button
      size={size}
      colorScheme={"red"}
      background={"red.500"}
      onClick={onClick}
    >
      {name || "Get Started"}
    </Button>
  );
};

export default AuthButton;
