import React from "react";
import {
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  Flex,
  Link,
} from "@chakra-ui/react";
import { FaTwitter } from "react-icons/fa";
import FooterLink from "./FooterLink";
import { useRouter } from "../util/router";

const Footer = () => {
  const router = useRouter();
  return (
    <Flex
      direction={["column", "column", "row"]}
      justifyContent="space-between"
      p={[8, 8, 16]}
      background="gray.100"
      color="gray.900"
    >
      <Stack
        direction={"column"}
        alignItems={["center", "center", "flex-start"]}
        spacing={4}
      >
        <Button
          variant="link"
          onClick={() => {
            // eslint-disable-next-line no-undef
            $crisp.push(["do", "chat:open"]);
          }}
        >
          Contact
        </Button>
        <Button
          variant="link"
          onClick={() => {
            router.push("/privacy");
          }}
        >
          Privacy Policy
        </Button>
        <Button
          variant="link"
          onClick={() => {
            router.push("/tos");
          }}
        >
          Terms of Service
        </Button>
      </Stack>
      <Flex
        direction="column"
        align="center"
        justifyContent="space-evenly"
        mt={[16, 16, 0]}
      >
        <Text fontWeight="bold" textAlign="center">
          Made with ❤️ for creators by
        </Text>
        <Button
          mt={[2, 2, 0]}
          bg={"blackAlpha.200"}
          _hover={{ boxShadow: "white" }}
        >
          <Link href="https://twitter.com/therajatkapoor" isExternal>
            <Icon as={FaTwitter} marginRight={2}></Icon>
            @therajatkapoor
          </Link>
        </Button>
      </Flex>
      <Flex
        direction={"column"}
        alignItems={["center", "center", "flex-end"]}
        justifyContent="start"
        mt={[16, 16, 0]}
      >
        <Text fontSize="xs">Ubiq apps</Text>
        <Text fontSize="xs">Copyright &copy;{new Date().getFullYear()}</Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
