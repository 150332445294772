import React, { useState, useContext } from "react";
import { ConfigContext } from "../util/state";
import { ChromePicker, SketchPicker } from "react-color";

import {
  Stack,
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Input,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  Button,
  Icon,
  TabPanels,
  Flex,
  Text,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useOutsideClick,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import { FaEdit } from "react-icons/fa";
import ProOnlyBadge from "./ProOnlyBadge";

const ColorPicker = ({ color, setColor, name, showAlpha, disabled }) => {
  showAlpha = !!showAlpha || false;
  const disableAlpha = !showAlpha;
  const [show, setShow] = useState(false);

  const ref = React.useRef();
  useOutsideClick({
    ref: ref,
    handler: () => setShow(false),
  });

  return (
    <Stack w="full">
      <Text fontWeight="semibold" size="lg" textTransform="uppercase">
        {name} {disabled && <ProOnlyBadge />}
      </Text>
      <Flex
        direction={"row"}
        borderRadius={2}
        borderWidth={"1px"}
        borderStyle={"solid"}
        borderColor={"gray.100"}
        position={"relative"}
      >
        <Box w={"full"} bg={color} rounded={4} overflow="hidden">
          <Button
            onClick={() => setShow(true)}
            padding={4}
            type={"button"}
            rightIcon={<FaEdit />}
            float={"right"}
            roundedRight={0}
            roundedLeft={0}
            disabled={disabled}
          >
            Choose
          </Button>
        </Box>
        {show && !disabled && (
          <Flex
            position={"absolute"}
            ref={ref}
            right={0}
            top={"45px"}
            zIndex={1000}
          >
            <ChromePicker
              disableAlpha={disableAlpha}
              onChange={setColor}
              color={color}
            ></ChromePicker>
          </Flex>
        )}
      </Flex>
    </Stack>
  );
};

export default ColorPicker;
