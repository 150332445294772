import React, { useState, useContext } from "react";
import { ConfigContext } from "../util/state";
import {
  Tooltip,
  Stack,
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Input,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  Button,
  Icon,
  TabPanels,
  Flex,
  Text,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useOutsideClick,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
} from "@chakra-ui/react";
import SliderControl from "./SliderControl";
import ColorPicker from "./ColorPicker";
import ProOnlyBadge from "./ProOnlyBadge";

const ScenePanel = () => {
  const [state, dispatch, isPro] = useContext(ConfigContext);
  const {
    sections,
    text1,
    text2,
    sideText,
    sceneHeight,
    sceneWidth,
    perspectiveX,
    perspectiveY,
    bgColor,
  } = state;
  return (
    <Stack>
      <SliderControl
        name="Scene Width"
        step={5}
        min={200}
        max={1000}
        onChange={(val) => {
          dispatch({ type: "DIMENSION", sceneWidth: val });
        }}
        value={sceneWidth}
      />
      <SliderControl
        name="Scene Height"
        min={200}
        step={5}
        max={1000}
        onChange={(val) => {
          dispatch({ type: "DIMENSION", sceneHeight: val });
        }}
        value={sceneHeight}
      />
      <Stack align="start" w="full">
        <ColorPicker
          name="background color"
          color={bgColor}
          setColor={(color) => {
            console.log(
              "🚀 ~ file: ScenePanel.js ~ line 79 ~ ScenePanel ~ color",
              color
            );
            dispatch({ type: "BGCOLOR", bgColor: color.hex });
          }}
          disabled={!isPro}
        />
        <Checkbox
          onChange={(e) => {
            dispatch({ type: "TRANSPARENT", transparent: e.target.checked });
          }}
          isDisabled={!isPro}
        >
          Transparent Background {!isPro && <ProOnlyBadge />}
        </Checkbox>
      </Stack>
      <SliderControl
        name="X-Perspective"
        min={0}
        max={90}
        onChange={(val) => {
          dispatch({ type: "PERSPECTIVE", perspectiveX: val });
        }}
        value={perspectiveX}
        disabled={!isPro}
      />
      <SliderControl
        name="Y-Perspective"
        min={0}
        max={45}
        onChange={(val) => {
          dispatch({ type: "PERSPECTIVE", perspectiveY: val });
        }}
        value={perspectiveY}
        disabled={!isPro}
      />
    </Stack>
  );
};

export default ScenePanel;
