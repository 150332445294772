import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import PrivacyPage from "./privacy";
import TosPage from "./tos";
import PurchasePage from "./purchase";
import AuthPage from "./auth";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";
import { ThemeProvider } from "./../util/theme.js";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import EditorPage from "./editor";
import LoginPage from "./login";
import "@fontsource/pacifico";
import "@fontsource/caveat";
import { Helmet } from "react-helmet";

const fonts = {
  cursive: "Pacifico",
  handwritten: "Caveat",
};

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
    500: "teal",
  },
};
const theme = extendTheme({
  layerStyles: {
    base: {
      bg: "gray.50",
      border: "2px solid",
      borderColor: "gray.500",
    },
    selected: {
      border: "2px solid",
      borderColor: "teal.500",
      bg: "teal.100",
    },
  },
  colors,
  fonts,
});

function App(props) {
  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mockly | Stunning ebook mockups</title>
        <link rel="canonical" href="http://mockly.xyz" />
      </Helmet>
      <ThemeProvider>
        <AuthProvider>
          <Router>
            <Switch>
              <Route exact path="/" component={IndexPage} />
              <Route exact path="/editor" component={EditorPage} />
              <Route exact path="/privacy" component={PrivacyPage} />
              <Route exact path="/tos" component={TosPage} />
              {/* 
              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/pricing" component={PricingPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/dashboard" component={DashboardPage} />

              
            <Route exact path="/purchase/:plan" component={PurchasePage} /> */}
              <Route exact path="/settings/:tab" component={SettingsPage} />
              <Route exact path="/settings/" component={SettingsPage} />

              <Route exact path="/auth/:type" component={LoginPage} />

              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default App;
