import React from "react";
import { Badge, Flex } from "@chakra-ui/react";
import { useRouter } from "../util/router";
import { Tooltip } from "@chakra-ui/react";

const ProOnlyBadge = ({ disableClick }) => {
  const ref = React.createRef(null);

  const router = useRouter();
  return (
    <Tooltip
      label="This is only available on PRO plan! Click to know more"
      aria-label="A tooltip"
      isDisabled={disableClick}
      closeDelay={500}
    >
      <Badge
        colorScheme="teal"
        onClick={() => {
          if (!disableClick) {
            router.push("/settings/plan");
          }
        }}
        cursor="pointer"
      >
        PRO
      </Badge>
    </Tooltip>
  );
};

export default ProOnlyBadge;
