import React, { useContext } from "react";
import { ConfigContext } from "../util/state";
import {
  Stack,
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
} from "@chakra-ui/react";
import { Radio, RadioGroup } from "@chakra-ui/react";

import ProductPanel from "./ProductPanel";
import ScenePanel from "./ScenePanel";
import { useAuth } from "../util/auth";

const Configurator = () => {
  const [state, dispatch, isPro] = useContext(ConfigContext);
  const { user } = useAuth();

  window.localStorage.setItem("__ss", JSON.stringify(state));

  return (
    <Stack spacing={4} w={"full"}>
      <Accordion defaultIndex={[1]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Heading size="md">Scene</Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <ScenePanel />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Heading size="md">Product Style</Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <ProductPanel />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

export default Configurator;
