import React, { Children } from "react";
import { Box, Flex, Text, Link } from "@chakra-ui/react";

const FooterLink = ({ text, href }) => {
  return (
    <Box
      _hover={{
        cursor: "pointer",
        textDecoration: "underline",
        textDecorationColor: "white",
      }}
    >
      <Link href={href}>
        <Text fontSize="xl" textAlign={"center"}>
          {text}
        </Text>
      </Link>
    </Box>
  );
};

export default FooterLink;
