import React, { useState, useRef, useEffect } from "react";
import { Container, Box, Stack, Flex, Button } from "@chakra-ui/react";
import { requireAuth, useAuth } from "../util/auth";

import { ConfigContextProvider } from "../util/state";

import Main from "../components/Main";
import Navbar from "../components/Navbar";

import Configurator from "../components/Configurator";

import { useScreenshot } from "use-screenshot-hook";
import { triggerBase64Download } from "react-base64-downloader";
import { apiRequest } from "../util/util";
import { useRouter } from "../util/router";
import firebase from "../util/firebase";

function EditorPage(props) {
  const { user } = useAuth();
  const router = useRouter();
  const [trigger, setTrigger] = useState(false);

  const downloadImage = async () => {
    firebase.analytics().logEvent("Image downloaded");

    await takeScreenshot();
    setTrigger(!trigger);
  };

  useEffect(() => {
    apiRequest("check-plan");
  }, [user]);
  // if (!user.licenseKey) {
  //   router.push("/settings");
  // }

  const ref = useRef();
  const { image, takeScreenshot } = useScreenshot({ ref });

  useEffect(() => {
    if (image) {
      triggerBase64Download(image, "mockup");
    }
  }, [trigger]);

  return (
    <ConfigContextProvider>
      <Stack>
        <Box border="2px" borderColor="gray.200" w={"full"} paddingX={16}>
          <Navbar />
        </Box>

        <Stack
          direction="row"
          minW="100%"
          // align="center"
          // border="2px"
          // borderColor="gray.200"
        >
          <Flex
            direction="column"
            width={"100%"}
            align="center"
            justify="center"
          >
            <Main sceneRef={ref} />
            <Button width="auto" m={4} onClick={downloadImage}>
              Save Image
            </Button>
          </Flex>
          <Box
            overflowY="scroll"
            borderLeftWidth="2px"
            borderColor="gray.200"
            w="400px"
            height={"calc(90vh)"}
          >
            <Configurator />
          </Box>
        </Stack>
      </Stack>
    </ConfigContextProvider>
  );
}

export default requireAuth(EditorPage);
