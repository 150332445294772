import React, { useState, useRef, useEffect } from "react";
import { requireAuth, useAuth } from "../util/auth";
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconButton,
  createIcon,
  Link,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Plans from "../components/Plans";
import Footer from "../components/FooterNew";
import { ArrowRight, ArrowRightAltRounded } from "@material-ui/icons";
import AuthButton from "../components/AuthButton";
import { useRouter } from "../util/router.js";
import mockup from "../assets/mockup.png";
import mockupn from "../assets/mockupn.png";
import twitteralpha from "../assets/twitteralpha.png";
import writealpha from "../assets/writealpha.png";
import reactm from "../assets/reactm.png";
import firebase from "../util/firebase";

const Arrow = createIcon({
  displayName: "Arrow",
  viewBox: "0 0 72 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
      fill="currentColor"
    />
  ),
});

const yolo = () => (
  <Box>
    <Icon
      as={Arrow}
      color={"gray.800"}
      w={70}
      position={"absolute"}
      right={-50}
      top={"10px"}
    />
    <Text
      fontSize={"lg"}
      fontFamily={"Caveat"}
      position={"absolute"}
      right={"-68px"}
      top={"-48px"}
      transform={"rotate(10deg)"}
    >
      <Text textDecoration={"line-through"} as={"span"}>
        {" "}
        $5/mo{" "}
      </Text>
      <Text as={"div"}>FREE*</Text>
    </Text>
  </Box>
);

const Rotater = (props) => {
  return (
    <motion.div
      animate={{
        rotate: [15, -15, 15],
      }}
      transition={{
        duration: 5,
        type: "spring",
        damping: 20,
        loop: Infinity,
      }}
    >
      <Flex>{props.children}</Flex>
    </motion.div>
  );
};

const FeatureSize = ({ img, text, ml }) => {
  const FlexM = motion(Flex);
  return (
    <Flex align="center" justify="space-around" direction={["column"]} w="100%">
      <FlexM
        w="full"
        align="center"
        justify="center"
        animate={{
          scale: [0.9, 1.4, 0.9],
        }}
        transition={{
          duration: 8,
          type: "tween",
          stiffness: 90,
          loop: Infinity,
        }}
      >
        <Image
          alt={"Mockup1"}
          fit={"contain"}
          align={"center"}
          h={"400px"}
          src={img}
          marginLeft={ml}
          filter="hue-rotate(var(--rotate))"
          transform="scale(0.8)"
        />
      </FlexM>
      <Heading size="lg" textAlign="center" color="gray.500">
        {text}
      </Heading>
    </Flex>
  );
};

const Feature = ({ img, text, ml }) => {
  const FlexM = motion(Text);
  return (
    <Flex
      align="center"
      justify="space-around"
      direction={["column"]}
      w="100%"
      position="relative"
    >
      <Image
        alt={"Mockup1"}
        fit={"contain"}
        align={"center"}
        h={"400px"}
        src={img}
        marginLeft={ml}
      />
      <FlexM
        align="center"
        justify="center"
        animate={{
          scale: [1, 2, 1],
        }}
        transition={{
          duration: 2,
          type: "spring",
          damping: 20,
          loop: Infinity,
        }}
        position="absolute"
        transform="translateX(50%)"
        top={"25%"}
        left={"75%"}
        z-index={1000}
      >
        ✨
      </FlexM>
      <FlexM
        align="center"
        justify="center"
        animate={{
          scale: [0.8, 2.4, 0.8],
        }}
        transition={{
          duration: 1.8,
          type: "spring",
          damping: 20,
          loop: Infinity,
          delay: 0.1,
        }}
        position="absolute"
        transform="translateX(50%)"
        top={"30%"}
        left={"27%"}
        z-index={1000}
      >
        ✨
      </FlexM>
      <FlexM
        align="center"
        justify="center"
        animate={{
          scale: [1.6, 0.9, 1.6],
        }}
        transition={{
          duration: 1.8,
          type: "spring",
          damping: 20,
          loop: Infinity,
          delay: 0.3,
        }}
        position="absolute"
        transform="translateX(50%)"
        top={"53%"}
        left={"40%"}
        z-index={1000}
      >
        ✨
      </FlexM>

      <Heading size="lg" textAlign="center" color="gray.500">
        {text}
      </Heading>
    </Flex>
  );
};

const FeatureColor = ({ img, text, ml }) => {
  const FlexM = motion(Flex);
  return (
    <Flex align="center" justify="space-around" direction={["column"]} w="100%">
      <FlexM
        w="full"
        align="center"
        justify="center"
        initial={{ "--rotate": "0deg" }}
        animate={{
          "--rotate": "360deg",
        }}
        transition={{
          duration: 4,
          // type: "spring",
          // damping: 20,
          loop: Infinity,
          // delay: Math.random(),
        }}
      >
        <Image
          alt={"Mockup1"}
          fit={"contain"}
          align={"center"}
          h={"400px"}
          src={img}
          marginLeft={ml}
          filter="hue-rotate(var(--rotate))"
          transform="scale(0.8)"
        />
      </FlexM>
      <Heading size="lg" textAlign="center" color="gray.500">
        {text}
      </Heading>
    </Flex>
  );
};

export const Blob = (props) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};
function IndexPage(props) {
  const auth = useAuth();
  const router = useRouter();
  firebase.analytics().logEvent("home page viewed");
  return (
    <>
      <Stack w={["100%"]}>
        <Flex px={[4, 8, 16, 32]}>
          <Navbar />
        </Flex>
        <Stack
          align={"center"}
          spacing={{ base: 0, md: 10 }}
          py={{ base: 0, md: 28 }}
          px={[4, 8, 16, 32]}
          direction={{ base: "column-reverse", md: "row" }}
        >
          <Stack spacing={{ base: 5, md: 10 }} px={8} w="full">
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
            >
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: "30%",
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "red.100",
                  zIndex: -1,
                }}
              >
                Stunning mockups,
              </Text>
              <br />
              <Text as={"span"} position={"relative"}>
                for your ebooks
              </Text>
              <br />
              <Text as={"span"} color={"red.500"}>
                in minutes!
              </Text>
            </Heading>
            <Heading color={"gray.500"} size={"lg"}>
              Create simple yet beautiful ebook mockups for your next Gumroad or
              Product Hunt launch
            </Heading>
            <Stack>
              <Link
                href="https://www.producthunt.com/posts/mockly?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-mockly"
                href="https://www.producthunt.com/posts/mockly?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-mockly"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=301838&theme=light&period=daily"
                  alt="Mockly - Stunning ebook mockups in minutes | Product Hunt"
                  // style="width: 250px; height: 54px;"
                  width="250"
                  height="54"
                />
              </Link>
              <Flex>
                <AuthButton onClick={() => router.push("/editor")} />
              </Flex>
            </Stack>
          </Stack>
          <Flex
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
          >
            <Box position="absolute" top={0} left={"50%"}>
              <Heading fontFamily="handwritten" transform="rotate(-15deg)">
                Made in 60 seconds
              </Heading>
              <Arrow
                height="30px"
                width="170px"
                transform="translate(20%, 85%) rotate(-60deg)"
              />
            </Box>
            <Blob
              w={"150%"}
              h={"150%"}
              position={"absolute"}
              top={"-20%"}
              left={0}
              zIndex={-1}
              color={useColorModeValue("red.100", "red.500")}
            />
            <Box
              position={"relative"}
              height={"100%"}
              rounded={"2xl"}
              width={"full"}
              overflow={"hidden"}
            >
              <motion.div
                animate={{
                  rotate: [15, -15, 15],
                }}
                transition={{
                  duration: 5,
                  type: "spring",
                  damping: 20,
                  loop: Infinity,
                }}
              >
                <Image
                  alt={"Hero Image"}
                  fit={"contain"}
                  align={"center"}
                  w={"100%"}
                  h={"400px"}
                  src={mockupn}
                  marginLeft={"-5%"}
                />
              </motion.div>
            </Box>
          </Flex>
        </Stack>
      </Stack>
      <Stack w="100vw" my={16} py={8} px={[4, 8, 16, 32]}>
        <Stack align="center" justify="center">
          <Heading
            color="red.400"
            size="3xl"
            px={[4]}
            fontFamily="cursive"
            textAlign="center"
          >
            Highly customizable mockups
          </Heading>
          <Flex
            align="center"
            direction={["column", "column", "row"]}
            justify="space-around"
            py={8}
          >
            <FeatureColor
              text="Customize colors to highlight your brand"
              img={twitteralpha}
              ml="-150px"
            />
            <FeatureSize
              ml="-70px"
              text="Change image as well as product mockup sizes"
              img={mockupn}
            />
            <Feature
              ml="-90px"
              text="Export a beautiful, ready to use .png for your product"
              img={writealpha}
            />
            {/* <Flex
              align="center"
              justify="space-around"
              direction={["column"]}
              w="100%"
            >
              <Image
                alt={"Mockup1"}
                fit={"contain"}
                align={"center"}
                h={"400px"}
                src={twitteralpha}
              />
              <Heading size="lg" textAlign="center" color="gray.500">
                Customize colors to highlight your brand. Change image as well
                as product mockup sizes. Export a beautiful, ready to use .png
                for your product
              </Heading>
            </Flex> */}
          </Flex>
          <AuthButton size="lg" onClick={() => router.push("/editor")} />
        </Stack>
      </Stack>
      <Plans />
      <Flex w="full" align="center" justify="center" mb={4}>
        <Link
          href="https://www.producthunt.com/posts/mockly?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-mockly"
          href="https://www.producthunt.com/posts/mockly?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-mockly"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=301838&theme=light&period=daily"
            alt="Mockly - Stunning ebook mockups in minutes | Product Hunt"
            // style="width: 250px; height: 54px;"
            width="250"
            height="54"
          />
        </Link>
      </Flex>
      <Footer />
    </>
  );
}

export default IndexPage;
